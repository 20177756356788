
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import gdbx from '@/store/modules/gdbx';
// tslint:disable:max-line-length
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import CashOnHandNumberDisplayer from '@/components/reports-v2/components/codedWidgets/gl/CashOnHandNumberDisplayer.vue';
import CashInBankNumberDisplayer from '@/components/reports-v2/components/codedWidgets/gl/CashInBankNumberDisplayer.vue';
import CashOutFlowTable from '@/components/reports-v2/components/codedWidgets/gl/CashOutFlowTable.vue';
import CashInFlowTable from '@/components/reports-v2/components/codedWidgets/gl/CashInFlowTable.vue';
import CashFlowBarChart from '@/components/reports-v2/components/codedWidgets/bp/CashFlowBarChart.vue';

@Component({
	components: {
		StickyFilterContainer,
		DateFilter,
		LastSync,
		SelectFilter,
		CashOnHandNumberDisplayer,
		CashInBankNumberDisplayer,
		CashOutFlowTable,
		CashInFlowTable,
		CashFlowBarChart,
	},
})
export default class CashFlow extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('year').valueOf(),
		moment().endOf('day').valueOf(),
	];

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('day').valueOf();
	}

	public get currentYearRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('year').valueOf(),
		];
	}

	public get selectedAgents() {
		return gdbx.allAgents;
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
