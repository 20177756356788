
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, CashFlow } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		TableFilterable,
		SelectFilter,
	},
})
export default class CashOutFlowTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'duePeriod'
	> = ['dateAsOf', 'duePeriod'];

	public selectedPeriod: '1 day' | '14 days' | '30 days' | '60 days' = '30 days';
	public periodOptions = ['1 day', '14 days', '30 days', '60 days'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');

		return 'Cash Out Flow' + '_' + 'As Of ' + formattedDate;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get selectedDate() {
		const periodArgument = this.selectedPeriod.split(' ');
		const day = Number(periodArgument[0]);
		return day;
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedDate } = this;
		return JSON.stringify([selectedAsOfDate, selectedDate]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('cashflow').numberRange('postDate', [
			[
				'>=',
				moment(this.selectedAsOfDate).add(-this.selectedDate, 'day').valueOf(),
			],
			['<=', this.selectedAsOfDate],
		]);

		const cards: CashFlow[] = await (await ref.get()).getCards();

		this.tableFields = [
			{
				key: 'date',
				sortable: true,
				formatter: TableItemFormatter.date,
			},
			{
				key: 'from',
				sortable: false,
			},
			{
				key: 'cash_out',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'to',
				sortable: false,
			},
			{
				key: 'purpose',
				sortable: false,
			},
		];

		const result = cards.map((item) => ({
			date: item.postDate,
			from: gdbx.accountNames[item.code],
			cash_out: item.cashOut,
			to: item.fromTo,
			purpose: item.purpose,
		}));

		this.tableItems = result.filter((item) => item.cash_out !== 0);

		this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
