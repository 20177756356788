
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts/core';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import { addComma } from '@/util/number';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import { DataViewFormatter } from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';

@Component({
	components: {
		StackedBarChartFilterable,
		TableCollapseToggle,
	},
})
export default class CashFlowBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'year'
	> = ['year'];

	public eachMonthTotal: any[] = [];
	public accumulatedTotal: any[] = [];
	public xAxisData: string[] = [];
	public periodTotalData: any[] = [];
	public detailFields: any[] = [];
	public series: echarts.EChartsCoreOption[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get dataViewFormatter() {
		return DataViewFormatter.cashFlow;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedDateRange } = this;
		return JSON.stringify([selectedAsOfDate, selectedDateRange]);
	}

	public async expensiveCalc() {
		const cashBankRef = FilteredDatabase.ref('transactions')
			.includes('accType', ['CA'])
			.includes('specialAccType', ['CH', 'BA']);

		const dateAsOf = this.selectedDateRange[0] - 1;

		const cashBankAsOfRef = cashBankRef.clone().dateAsOf(dateAsOf);

		const cashBankMonthlyRef = cashBankRef
			.clone()
			.dateRange(this.selectedDateRange);

		const monthlyCashBank = await this._loadDimensionByPeriod(
			cashBankMonthlyRef,
			'month',
			1,
			0,
			'amount',
		);

		const cloneCashBankEachMonth = monthlyCashBank.map((dd) => dd.sum);

		const currentMonth = moment().endOf('month');
		const isThisYear = this.selectedDateRange[1] > currentMonth.valueOf();
		const currentMonthIndex = isThisYear ? currentMonth.month() + 1 : 12;

		let asOfCashBank = await (await cashBankAsOfRef.get()).getSum('amount');

		const eachMonthResults: Array<{ value: number; itemStyle: any }> = [];
		const accumulatedResults: number[] = [];

		for (let i = 0; i <= monthlyCashBank.length; i++) {
			const object = { value: 0, itemStyle: { color: '' } };
			if (i >= currentMonthIndex) {
				continue;
			}

			asOfCashBank += cloneCashBankEachMonth[i];
			object.value = cloneCashBankEachMonth[i];
			object.itemStyle.color =
				object.value > 0 && object.value !== 0
					? GobiColor.COLOR1
					: GobiColor.COLOR3;

			eachMonthResults[i] = object;
			accumulatedResults[i] = asOfCashBank;
		}

		this.accumulatedTotal = accumulatedResults;
		this.eachMonthTotal = eachMonthResults;

		const totalNetCashFlow = monthlyCashBank
			.map((dd) => dd.sum)
			.reduce((a, b) => a + b, 0);

		this.periodTotalData = [
			{
				net_cash_flow:
					this.currencySymbol +
					' ' +
					addComma(totalNetCashFlow, gdbx.numDecimal, true),
			},
		];

		this.detailFields = [
			{
				key: 'net_cash_flow',
				label: 'Net Cash Flow',
			},
		];

		const netCashFlow: echarts.EChartsCoreOption | any = {
			name: 'Net Cash Flow',
			type: 'bar',
			data: this.eachMonthTotal,
		};

		const cashLevel: echarts.EChartsCoreOption | any = {
			name: 'Cash Level',
			type: 'line',
			data: this.accumulatedTotal,
			itemStyle: {
				color: GobiColor.COLOR7,
			},
		};

		this.xAxisData = monthlyCashBank.map((dd) => dd.text);

		this.series = [cashLevel, netCashFlow];

		this.saveHistory(
			'series',
			'xAxisData',
			'eachMonthTotal',
			'accumulatedTotal',
		);
	}
}
